<template>

    <!-- In order to use different/new icons need to import them in dashboard.js - mdi{IconName}-->
    <div class="box icon-wrapper cursor-pointer" :style="wrapperStyles" v-on:click="$emit('click')">
        <v-mdi :name="className" :fill="color" :size="size"></v-mdi>
    </div>

</template>

<script>

export default {

    name: "icon",
    props:{

        name: {
            type: String,
            required:true
        },

        plain: {
            type: Boolean,
            default: false
        },

        family: {
            type: String,
            default: 'outline'
        },

        size: {
            type: [ String, Number ],
            default: 14
        },

        bgColor: {
            type: String
        },

        bgSize: {
            type:  [ String, Number ],
            default: 32
        },

        color: {
            type: String
        },

        stroke: {
            type: [ String, Number ]
        }
    },

    computed: {

        className(){
            return 'mdi-' + this.name
        },

        wrapperStyles(){
            return  {

                backgroundColor: this.bgColor,
                height: this.plain ? '100%' : this.bgSize+'px',
                width: this.plain ? '25px' : this.bgSize+'px',
                stroke: this.color,
                strokeWidth: this.stroke
          }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@sass/_variables';

    .img-container {
        line-height: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
</style>
